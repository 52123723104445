.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);  
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.slide img {
  width: 150px;
  background-position: center;
  background-size: cover;
  height: 100px;
  /* animation: scroll 50s linear infinite; */
}


.slide-track {
  width: 100%;
  display: flex;
  gap: 3em;
  overflow: hidden;
}

.slider {
  /* margin-top: 150px; */
  background-color: #fff;
  padding: 4em 2em;
}

.showeScroll_header{
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
}

@keyframes scroll {
  0% {transform: translateX(0);}
  100% {transform: translatex(-4500%)}
}


@media (min-width: 992px) {
  .collapased_btn,.collaspe_btn{
    display: none !important;
  }
 }


 @media (min-width: 992px) and (max-width:1440px) {
  /* .logo_top_img{
    width: 60px !important;
  } */
   .box-oxxxx{
     display: none !important;
    }
    .nav_not_home{
      display: flex;
      justify-content: right;
      /* margin-right: 50px; */
    }
    .navbar-light .navbar-nav .nav-link {
      margin: 0px 0px;
  }
  
  }
 @media (min-width: 0) and (max-width:992px) {

   .logo_img{
  display: flex;
  justify-content: center;
  align-items: center;
   }
   /* .navbar_style{
    background-color: #fff;
   } */
   .logo_top_img{
    width: 55px !important;
    top: 5px !important;
    
  }
 
  .other_page_img{
    /* top: 11px !important; */
    /* width: 65px !important; */
  }
  .drop_title{
    border-top: 0.5px solid rgba(77, 74, 74, 0.133);
  
    padding: 0 30px ;
   
  }
  .servicename_nodrop{
    padding: 0 30px;
    border-top: 1px solid rgba(77, 74, 74, 0.133);
  }
  .drop_subtitle{
    border-top: 1px solid rgba(77, 74, 74, 0.133);
  }
  .drop_title .nav-link , .servicename_nodrop .nav-link{
   color: #282c34 !important;
  }
 
  .servicename_nodrop a{
    font-weight: 600 !important;
    
  }
 
  .dropdown-toggle{
    font-weight: 600 !important;

  }
  .all_title_text{
    /* padding-left: 30px;
    padding-right: 20px; */
    /* line-height: 10px; */
    padding-top: 10px;
  }
  .dropdown_icon{
    /* padding: 0px 0px ; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ul_nav_subtitle{
    box-shadow: none !important;
    border-left: none;
    border-right: none;
    border-top: none !important;
    width: 100% !important;
    border-radius: 0;
    padding: 0 0 !important;
   
  }
  /* .all_title_text li:last-of-type{
    border-top: none !important;
  } */
  .ul_nav_title{
    background-color: #fff;
   
  }
  .nav_box{
    border-top: 1px solid #000b2220;
  }
  }
  

  /* \\ ************************  footer ***************** // */


  .footer {
    position: relative;
    width: 100%;
    background: #09005a;
    min-height: 100px;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 11rem;
  }
  
  
  .menu {
    position: relative;
    margin: 10px 50px 0 0; 
    flex-wrap: wrap;
  }
  
  
  .menu__item {
    list-style: none;
    margin-bottom: 15px;
  }
  
  .menu__link {
    font-size: 1.2rem;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
    transition: 0.5s;
    text-decoration: none;
    opacity: 0.75;
    font-weight: 300;
  }
  
  .menu__link:hover {
    opacity: 1;
  }
  
  .footer p {
    color: #fff;
    margin: 15px 0 10px 0;
    font-size: 1rem;
    font-weight: 300;
  }
  
  .wave {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(".././public/assets/img/footer.png");
    background-size: 1000px 100px;
  }
  
  .wave#wave1 {
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    animation: animateWaves 4s linear infinite;
  }
  
  .wave#wave2 {
    z-index: 999;
    opacity: 0.5;
    bottom: 10px;
    animation: animate 4s linear infinite !important;
  }
  
  .wave#wave3 {
    z-index: 1000;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWaves 3s linear infinite;
  }
  
  .wave#wave4 {
    z-index: 999;
    opacity: 0.7;
    bottom: 20px;
    animation: animate 3s linear infinite;
  }
  
  @keyframes animateWaves {
    0% {
      background-position-x: 1000px;
    }
    100% {
      background-positon-x: 0px;
    }
  }
  
  @keyframes animate {
    0% {
      background-position-x: -1000px;
    }
    100% {
      background-positon-x: 0px;
    }
  }

  .Logo img{
    width: 18%;
  }
.Logo p{
  width: 50%;
}

.menu__item:hover .menu__link{
  color: #fff;
}

.conatct_us {
  color: #fff;
}
.conatct_us a{
  color: #fff;
}
.conatct_us p{
  font-size: 18px;
}