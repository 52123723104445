




.brand_logo_slide_box img {
    width: 100px;
    height: 100%;
    background-position: center;
    background-size: cover;
    /* height: 100px; */
    /* animation: scroll 50s linear infinite; */
  }
.slick-track{
  display: flex !important;
  align-items: center;
}
  .img_box{
    display: flex !important;
    justify-content: center;
  }
  
  
  
  
  .slide-track {
    width: 100%;
    display: flex;
    gap: 3em;
    overflow: hidden;
    

  }
  .slick-next{
    display: none !important;
  }
  
  .slider {
    /* margin-top: 150px; */
    background-color: #fff;
    /* padding: 4em 2em; */
  }
  
  @keyframes scroll {
    0% {transform: translateX(0);}
    100% {transform: translatex(-4500%)}
  }

/* 
  @media (min-width: 768px) and (max-width: 991.98px) { 
 
   }
  @media (min-width: 428px) and (max-width: 991.98px) { 

}
  @media (min-width: 100px) and (max-width: 425px) { 
  
   } */