.dance_gallary_wrapper {
    width: fit-content;
    /* background: red; */
    display: flex;
    justify-content: center;
    margin:0 auto;
    column-gap: 70px;
    align-items: center;
    margin-top: 40px;
    flex-wrap: wrap;
}
.dance_gallary_box{
    display:flex;
    /* width: ; */
    align-items: center;
    justify-content: center;
  
    padding: 0 30px;
    /* background-color: red; */
}
.imggallarybox {
    margin: 0 5px;
    width: 250px;
    /* height: 450px; */
    overflow: hidden;
    margin-bottom: 20px;
    border: 2px solid rgba(135, 132, 132, 0.117);
    padding-bottom: 5px;
    border-radius: 5px;
    /* background-color: green; */



}

.imgbox_img{
    width: 100%;
    height: 150px;
    overflow: hidden;
    
    
    
}

.imgbox_img img{
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
   
}

.img_tag {
    /* width: 100%; */
    


}

.img_text{
    margin-top: 5px;
}

.errow_hide{
    display: none;
}